<template>
  <v-data-table
    :headers="getHeaders()"
    :items="tableData"
    disable-pagination
    fixed-header
    :options.sync="options"
    calculate-widths
    height="calc(100vh - 254px)"
    hide-default-footer
    :server-items-length="1"
    disable-filtering
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar dense class="custom-toolbar" flat>
        <v-toolbar-title style="font-size: 15px"
          >Tổng: {{ pagination.total }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn
          @click="$emit('handle-create')"
          class="mx-2"
          x-small
          fab
          dark
          color="indigo"
        >
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.training="{ item }">
      <v-chip
        v-if="item.training && item.training.status === 'P'"
        color="warning"
        dark
      >
        Chờ duyệt
      </v-chip>

      <v-chip
        v-if="item.training && item.training.status === 'S'"
        color="success"
        dark
      >
        Đã duyệt
      </v-chip>
      <v-chip v-if="!item.training" color="success" dark> Đã duyệt </v-chip>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn
        x-small
        @click="$emit('handle-edit', item)"
        fab
        dark
        color="primary"
      >
        <v-icon dark>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        class="ml-2"
        x-small
        @click="handleDelete(item.id)"
        fab
        dark
        color="error"
      >
        <v-icon dark>mdi-delete</v-icon>
      </v-btn>
    </template>
    <template v-slot:item.icon="{ item }">
      <img :src="`/storage/icons/${item.key}.svg`" alt="" />
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="$emit('handle-reset')">Refresh</v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { destroy } from "@/api/icon";
import dataTableMixin from "@/mixins/crud/data-table";
export default {
  mixins: [dataTableMixin(destroy)],
  computed: {
    headers() {
      return [
        { text: "Tên", value: "name" },
        { text: "Key", value: "key" },
        { text: "Tổ chức", value: "organization.name" },
        { text: "Icon", value: "icon" },

        {
          text: "Hành động",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
  methods: {},
};
</script>
